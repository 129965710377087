import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import { FaInstagram, FaTwitter, FaFacebookF } from "react-icons/fa"
export default function Layout({ children }) {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TofuTogether - Vegan & Vegetarian Dating</title>
        <meta
          property="og:site_name"
          content="TofuTogether - Vegan and Vegetarian Dating"
        />
        <meta
          property="og:title"
          content="TofuTogether - Vegan and Vegetarian Dating"
        />
        <meta
          property="og:description"
          content="Vegan and Vegetarian Dating. Become part of a growing community to share what matters to you and fellow vegan and vegetarians."
        />
        <meta
          name="description"
          content="Vegan and Vegetarian Dating. Become part of a growing community to share what matters to you and fellow vegan and vegetarians."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          itemprop="image"
          content={"https://tofutogether.com/logo512.png"}
        />
        <meta
          property="og:image:secure_url"
          itemprop="image"
          content={"https://tofutogether.com/logo512.png"}
        />
        <link rel="canonical" href="https://tofutogether.com" />
        <meta property="og:url" content="https://tofutogether.com" />

        <link rel="canonical" href="http://tofutogether.com" />
      </Helmet>
      {children}
      <div className="bg-black">
        <div class="container px-4 py-12 mx-auto text-white">
          <p className="mb-4">
            TofuTogether Copyright 2020. All Rights reserved.
          </p>
          <p className="mb-4">
            <Link to="/">Home</Link> |{" "}
            <Link to="/terms">Terms and conditions</Link> |{" "}
            <Link to="/privacy">Privacy Policy</Link>
          </p>
          <p className="mb-4">Email: support@tofutogether.com</p>
          <p className="mb-4">
            <a
              target="_blank"
              href="http://instagram.com/tofutogether"
              rel="noopener"
            >
              <FaInstagram className="text-3xl inline mr-6" />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/TofuTogether"
              rel="noopener"
            >
              <FaTwitter className="text-3xl inline  mr-6" />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/TofuTogether"
              rel="noopener"
            >
              <FaFacebookF className="text-3xl inline" />
            </a>
            <p className="mt-4">
              <a
                target="_blank"
                href="https://www.happycow.net
"
                rel="noopener"
                className="underline"
              >
                Find vegan-options near you with <strong>HappyCow</strong>
              </a>
            </p>
          </p>
        </div>
      </div>
    </div>
  )
}
